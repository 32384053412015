import React, { useState, useEffect } from "react";
import { PROTOCOL } from "../../protocol";
import Layout from "../../components/layout";
import { Link } from "gatsby";
import {
  FiCheckCircle,
  FiFrown,
  FiChevronDown,
  FiChevronUp,
} from "react-icons/fi";
import { set, get } from "idb-keyval";
import Collapsible from "react-collapsible";
import useIsIOS from "../../hooks/useIsIos";
import InstallPWA from "../../components/InstallPWA";

const Relaxation = () => {
  const [progress, setProgress] = useState({ inside: [], outside: [] });
  const [selectedValue, setSelectedValue] = useState(1);

  const { prompt } = useIsIOS();

  useEffect(() => {
    get("progress").then((val) => {
      if (val) {
        setProgress(val);
        setSelectedValue(val.inside.length + 1);
      } else {
        console.log("There is not data saved, creating fresh empty schema");
        set("progress", progress)
          .then(() => {
            console.log("It worked!");
          })
          .catch((err) => console.log("It failed!", err));
      }
    });
  }, []);

  const updateDay = (location, success) => {
    const newArray = progress[location].concat(success);
    //   useEffect(() => {
    set("progress", { ...progress, [location]: newArray })
      .then(() => {
        console.log("It worked!");
        setProgress({ ...progress, [location]: newArray });
      })
      .catch((err) => console.log("It failed!", err));
    //   }, [progress]);
  };

  const renderDay = (key, index) => {
    if (progress[key][index] === false) {
      return (
        <Link
          to="/relaxation-protocol"
          state={{ dayFromTable: index + 1, locationState: key }}
          className="button is-danger is-outlined is-small"
        >
          <span>Retry</span>
          <span className="icon is-small">
            <FiFrown className="has-text-danger" />
          </span>
        </Link>
      );
    } else if (progress[key][index]) {
      return <FiCheckCircle className="has-text-success" />;
    } else if (
      progress[key][index - 1] !== false &&
      progress[key].length === index
    ) {
      return (
        <Link
          to="/relaxation-protocol"
          state={{ dayFromTable: index + 1, locationState: key }}
          className="button is-success is-small"
        >
          Start
        </Link>
      );
    } else {
      return (
        <Link
          to="/relaxation-protocol"
          state={{ dayFromTable: index + 1, locationState: key }}
        >
          <FiCheckCircle className="has-text-grey-lighter" />
        </Link>
      );
    }
  };

  const handleDaySelected = (e) => {
    console.log("e", e.target.value);
    setSelectedValue(e.target.value);
  };

  const renderInstructions = () => {
    const renderStep = (title, text) => {
      return (
        <>
          <h3
            className={`title is-4`}
            style={{ paddingBottom: "0.5rem", paddingTop: "3.5rem" }}
          >
            {title}
          </h3>
          <p className="subtitle is-5" style={{ lineHeight: "1.4" }}>
            {text}
          </p>
        </>
      );
    };

    return (
      <div
        className=""
        style={{
          margin: "2rem 1rem",
          padding: "1rem",
        }}
      >
        <Collapsible
          trigger={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <h2 className="is-size-3 has-text-weight-bold">
                  Instructions{" "}
                </h2>
                <small className="has-text-success is-size-6 has-text-centered">
                  (start here)
                </small>
              </div>
              <FiChevronDown className="is-size-3" />
            </div>
          }
          triggerWhenOpen={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 className="is-size-3 has-text-weight-bold">Instructions</h2>
              <FiChevronUp className="is-size-3" />
            </div>
          }
        >
          <div style={{ marginBottom: "5rem" }}>
            {renderStep(
              "Get your dog in position",
              "Cue or lure your dog into a down or a sit. You can choose to do the Protocol on a mat or off a mat."
            )}
            {renderStep(
              "Do the task",
              "The app will tell you exactly what to do while your dog relaxes."
            )}
            {renderStep(
              "Reward them for succeeding",
              "When your dog succeeds reward them with a treat and calm praise."
            )}
            {renderStep(
              "If your dog gets up, try again",
              "On the second attempt break the task into smaller peices so your dog has a chance at succeeding. If they fail twice end the session."
            )}
            {renderStep(
              "Celebrate when you make it to the end!",
              "Show your dog some love. Don't worry about tracking your progress, the app will save it for you."
            )}

            <h3 className={`title is-4`} style={{ paddingTop: "3.5rem" }}>
              Still confused?
            </h3>
            <p style={{ paddingBottom: "0.5rem" }} className="subtitle">
              Read the in-depth guide:{" "}
              <a
                href="https://www.scottsschoolfordogs.com/tips/relaxation-protocol/"
                className="has-text-primary is-6"
              >
                Teach Your Dog to Relax with The Relaxation Protocol
              </a>
            </p>
            {/* Vidoe Section */}
            {/* <div
              style={{
                position: "relative",
                overflow: "hidden",
                paddingTop: "56.25%",
              }}
            >
              <iframe
                title="Mat training demo by Journey dog training"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                src="https://www.youtube-nocookie.com/embed/BqRh8r6rHuc"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullscreen="allowFullScreen"
              ></iframe>
            </div> */}
          </div>
        </Collapsible>
      </div>
    );
  };

  return (
    <Layout>
      {prompt && <InstallPWA />}
      <div className="container" style={{ maxWidth: "900px" }}>
        {renderInstructions()}
        <div className="card" style={{ margin: "4rem 1rem", padding: "1rem" }}>
          <h2 className="title has-text-weight-bold">Train</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "75px",
              marginBottom: "50px",
            }}
          >
            <div className="field">
              <div
                className=" "
                style={{ marginBottom: "15px", textAlign: "center" }}
              >
                <label
                  className=""
                  style={{
                    fontSize: "25px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Step
                </label>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <div className="control">
                    <div className="select is-rounded">
                      <select
                        value={selectedValue}
                        onChange={handleDaySelected}
                      >
                        {PROTOCOL.map((proto) => (
                          <option key={proto.day} value={proto.day}>
                            {proto.day}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ margin: "50px 0px" }}>
              <Link
                to="/relaxation-protocol"
                state={{
                  dayFromTable: selectedValue,
                  locationState: "inside",
                }}
                className="button is-success"
              >
                Start Step {selectedValue}
              </Link>
            </div>
          </div>
        </div>
        <div className="card" style={{ margin: "4rem 1rem", padding: "1rem" }}>
          <h2 className="title has-text-weight-bold">Progress</h2>

          <table className="table" style={{ margin: "auto" }}>
            <thead>
              <tr>
                <th>Step</th>
                <th>Progress</th>
              </tr>
            </thead>
            <tbody>
              {PROTOCOL.map((day, index) => {
                return (
                  <tr key={index}>
                    <th>{day.day}</th>
                    <td className="has-text-centered">
                      {renderDay("inside", index)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Relaxation;
