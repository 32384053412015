import React, { useState } from "react";
import DogLogo from "../images/noun_Dog_1609862.svg";
import ShareIcon from "../images/apple-share-icon.png";

const InstallPWA = () => {
  const [showModal, setShowModal] = useState(true);

  return (
    <div className={`modal ${showModal && "is-active"}`}>
      <div className="modal-background"></div>
      <div className="modal-content" style={{ overflow: "visible" }}>
        <div
          className="box container has-text-centered"
          style={{ maxWidth: "350px" }}
        >
          <figure
            className="image is-64x64 box"
            style={{
              position: "absolute",
              top: -20,
              left: 0,
              right: 0,
              margin: "auto",
            }}
          >
            <img src={DogLogo} />
          </figure>
          <h2
            className="title"
            style={{ marginBottom: "2.5rem", marginTop: "2rem" }}
          >
            Install calm<span className="has-text-success">dog</span>
          </h2>
          <p
            className="subtitle has-text-grey-light"
            style={{ marginBottom: "2rem" }}
          >
            Install this application on your homescreen for a better experience
          </p>
          <p style={{ marginBottom: "2rem" }}>
            Tap{" "}
            <span className="icon">
              <img src={ShareIcon} />
            </span>{" "}
            then "Add to Home Screen"
          </p>
          <button
            className="button is-text"
            onClick={() => setShowModal(false)}
          >
            close
          </button>
        </div>
      </div>

      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={() => setShowModal(false)}
      ></button>
    </div>
  );
};

export default InstallPWA;
